import axios from '../../axios-base';
import { showAlert } from '../alert/alertActions';

export const fetchUserRequest = () => ({
  type: 'FETCH_USER_REQUEST',
});

export const fetchUserSuccess = (user) => ({
  type: 'FETCH_USER_SUCCESS',
  payload: user,
});

export const fetchUserFailure = (error) => ({
  type: 'FETCH_USER_FAILURE',
  payload: error,
});

export const registerUser = (userData) => {
  return (dispatch) => {
    dispatch(fetchUserRequest());
    axios
      .post('/auth/register', userData)
      .then((response) => {
        dispatch({
          type: 'REGISTER_USER_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(fetchUserFailure(error.message));
        dispatch(
          showAlert(
            'danger',
            'No se ha podido registrar el usuario, intente con otro nombre de usuario',
          ),
        );
      });
  };
};

export const fetchUser = () => {
  return (dispatch) => {
    dispatch(fetchUserRequest());

    axios
      .get('auth/me')
      .then((response) => {
        dispatch(fetchUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserFailure(error.message));
      });
  };
};

export const loginUser = (userData) => {
  return (dispatch) => {
    dispatch({
      type: 'LOGIN_USER_REQUEST',
    });

    axios
      .post('/auth/login', userData)
      .then((response) => {
        dispatch({
          type: 'LOGIN_USER_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'LOGIN_USER_FAILURE',
          payload: error,
        });

        dispatch(showAlert('danger', 'Usuario o contraseña incorrectos'));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    axios
      .post('/auth/logout')
      .then(() => {
        dispatch({
          type: 'LOGOUT_USER_SUCCESS',
        });

        window.location.href = '/auth/login';
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const authMercadolibre = (body) => {
  return (dispatch) => {
    dispatch({
      type: 'MERCADOLIBRE_AUTH_USER_REQUEST',
    });

    axios
      .post('mercadolibre/auth', body)
      .then((response) => {
        dispatch({
          type: 'MERCADOLIBRE_AUTH_USER_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'MERCADOLIBRE_AUTH_USER_FAILURE',
          payload: error.response,
        });

        dispatch(showAlert('danger', 'No se puede conectar a mercadolibre'));
      });
  };
};
