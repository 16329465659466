import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import LoginFormik from "../views/auth/LoginFormik";
import Orders from "../views/Orders";
import DetailOrders from "../views/DetailOrders";
import Sales from "../views/Sales";

const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
const Error = Loadable(lazy(() => import("../views/auth/Error")));

const Starter = Loadable(lazy(() => import("../views/Starter")));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));

const AdminStarter = Loadable(lazy(() => import("../views/admin/AdminStarter")));
const AdminDrivers = Loadable(lazy(() => import("../views/admin/AdminDrivers")));
const AdminUpdateDrivers = Loadable(lazy(() => import("../views/admin/AdminUpdateDrivers")));
const AdminCreateDrivers = Loadable(lazy(() => import("../views/admin/AdminCreateDrivers")));
const AdminOrders = Loadable(lazy(() => import("../views/admin/orders/AdminOrders")));
const AdminDetailOrders = Loadable(lazy(() => import("../views/admin/orders/AdminDetailOrders")));
const AdminDriverPayments = Loadable(lazy(() => import("../views/admin/driverPayments/AdminDriverPayments")));
const AdminDriverPaymentsDetail = Loadable(lazy(() => import("../views/admin/driverPayments/AdminDriverPaymentsDetail")));
const AdminDriverPaymentsDetailOrders = Loadable(lazy(() => import("../views/admin/driverPayments/AdminDriverPaymentsDetailOrders")));

const AdminClientPayments = Loadable(lazy(() => import("../views/admin/clientPayments/AdminClientPayments")));
const AdminClientPaymentsDetail = Loadable(lazy(() => import("../views/admin/clientPayments/AdminClientPaymentsDetail")));
const AdminClientPaymentsDetailOrders = Loadable(lazy(() => import("../views/admin/clientPayments/AdminClientPaymentsDetailOrders")));
const AdminDriverShipmentPrice = Loadable(lazy(() => import("../views/admin/driverShipmentPrice/AdminDriverShipmentPrice")));

const AdminClients = Loadable(lazy(() => import("../views/admin/clients/AdminClients")));
const AdminUpdateClients = Loadable(lazy(() => import("../views/admin/clients/AdminUpdateClients")));
const AdminCreateClients = Loadable(lazy(() => import("../views/admin/clients/AdminCreateClients")));

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/starter" /> },
      { path: "/starter", exact: true, element: <Starter /> },
      { path: "/sales", exact: true, element: <Sales /> },
      { path: "/orders", exact: true, element: <Orders /> },
      { path: "/orders/:id/detail", exact: true, element: <DetailOrders /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/admin",
    element: <FullLayout />,
    children: [
      { path: "/admin", element: <Navigate to="/admin/client-payments" /> },
      { path: "/admin/starter", exact: true, element: <AdminStarter /> },
      { path: "/admin/drivers", exact: true, element: <AdminDrivers /> },
      { path: "/admin/drivers/:id/update", exact: true, element: <AdminUpdateDrivers /> },
      { path: "/admin/drivers/create", exact: true, element: <AdminCreateDrivers /> },
      { path: "/admin/clients", exact: true, element: <AdminClients /> },
      { path: "/admin/clients/:id/update", exact: true, element: <AdminUpdateClients /> },
      { path: "/admin/clients/create", exact: true, element: <AdminCreateClients /> },
      { path: "/admin/orders", exact: true, element: <AdminOrders /> },
      { path: "/admin/orders/:id/detail", exact: true, element: <AdminDetailOrders /> },
      { path: "/admin/driver-payments", exact: true, element: <AdminDriverPayments /> },
      { path: "/admin/driver-payments/:id/detail/:from/:to", exact: true, element: <AdminDriverPaymentsDetail /> },
      { path: "/admin/driver-payments/:id/detail/:from/:to/orders", exact: true, element: <AdminDriverPaymentsDetailOrders /> },
      { path: "/admin/client-payments", exact: true, element: <AdminClientPayments /> },
      { path: "/admin/client-payments/:id/detail/:from/:to", exact: true, element: <AdminClientPaymentsDetail /> },
      { path: "/admin/client-payments/:id/detail/:from/:to/orders", exact: true, element: <AdminClientPaymentsDetailOrders /> },
      { path: "/admin/driver-shipment-price", exact: true, element: <AdminDriverShipmentPrice /> },
      { path: "/admin/*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: 'register', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
    ],
  },
];

export default ThemeRoutes;
