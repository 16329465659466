import React from 'react';
import { Card, CardBody, CardHeader, FormGroup, Input } from 'reactstrap';
import './SalesCard.css';

const SalesCard = ({ sale, defaultChecked, onCheckboxChange }) => {
  const payment = sale.payments.find((item) => item.status === 'approved');

  return (
    <Card className={`venta-card ${defaultChecked ? 'selected' : ''}`}>
      <CardHeader>
        <FormGroup check>
          <Input type="checkbox" checked={defaultChecked} onChange={onCheckboxChange} />
          <strong className="venta-id">ID de Venta:</strong> {sale.id}
        </FormGroup>
      </CardHeader>
      <CardBody>
        <p>
          <strong>Título:</strong> {sale.order_items.map((item) => item.item.title).join('-')}
        </p>
        <p>
          <strong>Fecha:</strong> {sale.date_closed}
        </p>
        <p>
          <strong>Precio:</strong> ${payment.transaction_amount}
        </p>
        <p>
          <strong>Comprador:</strong> ID: {sale.buyer.id} - Nombre: {sale.buyer.nickname}
        </p>
      </CardBody>
    </Card>
  );
};

export default SalesCard;
