import axios from '../../axios-base';

export const fetchOrders = (page) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ORDERS_REQUEST',
    });

    axios
      .get(`orders?page=${page}`)
      .then((response) => {
        dispatch({
          type: 'FETCH_ORDERS_SUCCESS',
          payload: response.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_ORDERS_FAILURE',
          error: error.data,
        });
      });
  };
};

export const fetchAdminOrders = (page, filters) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ORDERS_REQUEST',
    });

    axios
      .get(`admin/orders`, {
        params: {
          page,
          ...filters
        }
      })
      .then((response) => {
        dispatch({
          type: 'FETCH_ORDERS_SUCCESS',
          payload: response.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_ORDERS_FAILURE',
          error: error.data,
        });
      });
  };
};

export const getAdminOrder = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ORDERS_REQUEST',
    });

    axios
      .get(`admin/orders/${id}`)
      .then((response) => {
        dispatch({
          type: 'GET_ORDER_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_ORDERS_FAILURE',
          error: error.data,
        });
      });
  };
};

export const getClientOrder = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ORDERS_REQUEST',
    });

    axios
      .get(`orders/${id}`)
      .then((response) => {
        dispatch({
          type: 'GET_ORDER_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_ORDERS_FAILURE',
          error: error.data,
        });
      });
  };
};