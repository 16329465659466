const initialState = null;

const DriverReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DRIVERS_REQUEST':
    case 'GET_DRIVER_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_DRIVERS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        updateStatus: '',
      };

    case 'FETCH_DRIVERS_FAILURE':
    case 'GET_DRIVER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'GET_DRIVER_SUCCESS':
    case 'UPDATE_DRIVER_SUCCESS':
      return {
        ...state,
        loading: false,
        currentDriver: action.payload,
        updateStatus: action.type === 'UPDATE_DRIVER_SUCCESS' ? 'OK' : '',
      }

    case 'DELETE_DRIVER_SUCCESS':
    case 'CREATE_DRIVER_SUCCESS':
      return {
        ...state,
        loading: false,
        currentDriver: action.payload,
        updateStatus: 'OK',
      }

    default:
      return state;
  }
};

export default DriverReducers;
