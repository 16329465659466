import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import ComponentCard from '../components/ComponentCard';
import OrderStatus from '../components/dashboard/OrderStatus';
import { getClientOrder } from '../store/orders/orderActions';
import './admin/orders/styles/AdminDetailOrders.css';

const DetailOrders = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orders?.currentOrder);

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getClientOrder(id));
  }, [dispatch, id]);

  return (
    <>
      {order?.id ? (
        <Col md="12">
          <Button className="mb-3" color="info" onClick={() => navigateBack()}>
            Atrás
          </Button>
          <ComponentCard title={`Detalle de Envío - #${order.external_id}`}>
            <Card className="mb-4 custom-card">
              <CardBody>
                <CardTitle tag="h5">Información del Envío</CardTitle>
                <Table striped>
                  <tbody>
                    <tr>
                      <td>ID Externo:</td>
                      <td>{order.external_id}</td>
                    </tr>
                    <tr>
                      <td>Título:</td>
                      <td>{order.title}</td>
                    </tr>
                    <tr>
                      <td>Precio:</td>
                      <td>{order.price}</td>
                    </tr>
                    <tr>
                      <td>Hora y fecha de entrega:</td>
                      <td>{order.delivered_at || 'No entregado'}</td>
                    </tr>
                    <tr>
                      <td>Intentos fallidos de entrega:</td>
                      <td>{order.attempts}</td>
                    </tr>
                    <tr>
                      <td>Estado:</td>
                      <OrderStatus status={order.status} onlyText />
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            <Card className="mb-4 custom-card">
              <CardBody>
                <CardTitle tag="h5">Repartidor</CardTitle>
                <Table striped>
                  <tbody>
                    <tr>
                      <td>Nombre:</td>
                      <td>{order.driver.name}</td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td>{order.driver.email}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            <Card className="mb-4 custom-card">
              <CardBody>
                <CardTitle tag="h5">Receptor</CardTitle>
                <Table striped>
                  <tbody>
                    <tr>
                      <td>Nombre:</td>
                      <td>{order.receiver.name}</td>
                    </tr>
                    <tr>
                      <td>Teléfono:</td>
                      <td>{order.receiver.phone}</td>
                    </tr>
                    <tr>
                      <td>Dirección:</td>
                      <td>{`${order.receiver.address_line}, ${order.receiver.street_number}`}</td>
                    </tr>
                    <tr>
                      <td>Ciudad:</td>
                      <td>{order.receiver.city}</td>
                    </tr>
                    <tr>
                      <td>Municipalidad:</td>
                      <td>{order.receiver.municipality || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>Código Postal:</td>
                      <td>{order.receiver.zip_code}</td>
                    </tr>
                    <tr>
                      <td>Estado:</td>
                      <td>{order.receiver.state}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </ComponentCard>
        </Col>
      ) : (
        <div>Cargando</div>
      )}
    </>
  );
};

export default DetailOrders;
