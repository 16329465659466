import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import CustomizerReducer from './customizer/CustomizerSlice';
import SalesReducer from './sales/salesReducers';
import UserReducer from './user/userReducers';
import AlertReducer from './alert/alertReducers';
import OrderReducer from './orders/orderReducers';
import DriverReducer from './drivers/driverReducers';
import DriverPaymentsReducer from './driverPayments/driverPaymentsReducer';
import ClientPaymentsReducer from './clientPayments/clientPaymentsReducer';
import LoaderReducer from './loader/loaderReducer';
import { requestStarted, requestCompleted} from './loader/loaderActions';

const loadingMiddleware = ({ dispatch }) => next => action => {
  if (action.type.endsWith('_REQUEST')) {
    dispatch(requestStarted());
  } else if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAILURE')) {
    dispatch(requestCompleted());
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    user: UserReducer,
    sales: SalesReducer,
    alert: AlertReducer,
    orders: OrderReducer,
    drivers: DriverReducer,
    clients: DriverReducer,
    driverPayments: DriverPaymentsReducer,
    clientPayments: ClientPaymentsReducer,
    loader: LoaderReducer,
  },
  middleware: [...getDefaultMiddleware(), loadingMiddleware],
});


export default store;
