import React from 'react';

const OrderZone = ({ status }) => {
  let statusText;

  switch (status) {
    case 'short':
      statusText = 'Corto';
      break;
    case 'medium':
      statusText = 'Medio';
      break;
    case 'long':
      statusText = 'Largo';
      break;
    default:
      statusText = 'No reconocido';
  }

  return <td>{statusText}</td>;
};

export default OrderZone;
