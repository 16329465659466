const initialState = null;

const ClientPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CLIENT_PAYMENTS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_CLIENT_PAYMENTS_SUCCESS':
    case 'FETCH_CLIENT_PAYMENTS_DETAIL_ORDERS_SUCCESS':
    case 'FETCH_CLIENT_PAYMENTS_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        currentClientPayments: action.currentClientPayments,
        payments: action.payments
      };

    case 'FETCH_CLIENT_PAYMENTS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ClientPaymentsReducer;
