const initialState = null;

const DriverPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DRIVER_PAYMENTS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_DRIVER_PAYMENTS_SUCCESS':
    case 'FETCH_DRIVER_PAYMENTS_DETAIL_ORDERS_SUCCESS':
    case 'FETCH_DRIVER_PAYMENTS_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        currentDriverPayments: action.currentDriverPayments,
        payments: action.payments
      };

    case 'FETCH_DRIVER_PAYMENTS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default DriverPaymentsReducer;
