import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Input, Button } from 'reactstrap';
import axiosInstance from '../axios-base';
import SalesCard from '../components/sales/SalesCard';
import { showAlert } from '../store/alert/alertActions';
import { fetchSales } from '../store/sales/salesActions';

const ventas = [
  {
    id: 1,
    fecha: '2023-07-20',
    comprador: 'Juan Pérez',
    destino: 'Ciudad A',
    instruccionesDestino: 'Entregar en la dirección X, contacto: XXX-XXX-XXXX',
  },
  {
    id: 2,
    fecha: '2023-07-19',
    comprador: 'María Gómez',
    destino: 'Ciudad B',
    instruccionesDestino: 'Dejar en la puerta, no llamar al timbre',
  },
];

const Sales = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSales, setSelectedSales] = useState([]);
  const sales = useSelector((state) => state.sales?.sales);
  const dispatch = useDispatch();

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedSales(ventas);
    } else {
      setSelectedSales([]);
    }
  };

  const handleSaleCheckboxChange = (sale) => {
    if (selectedSales.filter((s) => s.id === sale.id).length) {
      setSelectedSales(selectedSales.filter((s) => s.id !== sale.id));
    } else {
      setSelectedSales([...selectedSales, sale]);
    }
  };

  const handleCreateOrder = () => {
    selectedSales.forEach((sale) => {
      axiosInstance
        .get(`mercadolibre/shipments/${sale.shipping?.id}`)
        .then((response) => {
          const shipment = response.data;

          const order = {
            external_id: sale.id.toString(),
            external_buyer_id: sale.buyer?.id.toString(),
            external_seller_id: sale.seller?.id.toString(),
            title: sale.order_items.map((item) => item.item.title).join('-'),
            price: sale.total_amount.toString(),
            receiver: {
              address_line: shipment.receiver_address?.address_line,
              city: shipment.receiver_address?.city?.name,
              latitude: shipment.receiver_address?.latitude,
              longitude: shipment.receiver_address?.longitude,
              municipality: shipment.receiver_address?.municipality?.name,
              street_name: shipment.receiver_address?.street_name,
              zip_code: shipment.receiver_address?.zip_code,
              street_number: shipment.receiver_address?.street_number,
              name: shipment.receiver_address?.receiver_name,
              state: shipment.receiver_address?.state?.name,
              phone: shipment.receiver_address?.receiver_phone,
            },
          };

          axiosInstance
            .post(`orders`, order)
            .then((res) => {
              dispatch(showAlert('success', `Orden "${res.data.title}" creada correctamente`));
            })
            .catch((err) => {
              dispatch(showAlert('danger', `Error: ${err.response?.data?.message}`));
            });
        })
        .catch(() => {
          dispatch(showAlert('danger', 'No se puede obtener datos del envio'));
        });
    });
  };

  useEffect(() => {
    if (!sales) {
      dispatch(fetchSales());
    }
  }, [dispatch]);

  return (
    <>
      <div className="ventas-container">
      <h2>Ventas</h2>
      <FormGroup check className="select-all-container">
        <Input id="cbxSelectAll" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
        <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
        <label>Seleccionar todas las ventas</label>
      </FormGroup>
      {sales &&
        sales.map((sale) => (
          <SalesCard
            key={sale.id}
            sale={sale}
            selected={selectedSales.includes(sale.id)}
            defaultChecked={selectedSales.find((s) => s.id === sale.id)}
            onCheckboxChange={() => handleSaleCheckboxChange(sale)}
          />
        ))}
      {selectedSales.length > 0 && (
        <Button color="primary" onClick={handleCreateOrder}>
          Crear orden
        </Button>
      )}
    </div>

    {! sales?.length ? (<p>No hay ventas pendientes</p>) : null}
    </>
  );
};

export default Sales;
