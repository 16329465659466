import axios from '../../axios-base';

export const fetchSales = () => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_SALES_REQUEST',
    });

    axios
      .get('mercadolibre/sales')
      .then((response) => {
        dispatch({
          type: 'FETCH_SALES_SUCCESS',
          payload: response.data?.results,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_SALES_FAILURE',
          error: error.data,
        });
      });
  };
};
