import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from 'reactstrap';
import OrderStatus from '../components/dashboard/OrderStatus';
import OrderZone from '../components/dashboard/OrderZone';
import { fetchOrders } from '../store/orders/orderActions';

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const orders = useSelector((state) => state.orders?.orders);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const load = async (page) => {
    dispatch(fetchOrders(page));
  };

  useEffect(() => {
    load(currentPage);
  }, [dispatch, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUpdate = (id) => {
    navigate(`/orders/${id}/detail`);
  };

  return (
    <div>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Envios</CardTitle>
          {orders?.length ? (
            <>
              <Table className="no-wrap mt-3 align-middle" responsive borderless>
                <thead>
                  <tr>
                    <th>Fecha y hora</th>
                    <th>Order ID</th>
                    <th>Destino</th>
                    <th>Distancia</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.length &&
                    orders.map((order) => (
                      <tr key={order.external_id} className="border-top">
                        <td>{order.created_at}</td>
                        <td>{order.external_id}</td>
                        <td>{order.receiver.city}</td>
                        <OrderZone status={order.zone.name} />
                        <OrderStatus status={order.status} />
                        <td>
                          <Button
                            className="btn"
                            color="warning"
                            onClick={() => handleUpdate(order.id)}
                          >
                            Detalle
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Pagination aria-label="Orders pagination">
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{currentPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                </PaginationItem>
              </Pagination>
            </>
          ) : (
            <p>No hay ordenes creadas</p>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Orders;
