import React from 'react';

const OrderStatus = ({ status, onlyText = false }) => {
  let statusText;
  let statusStyle;

  switch (status) {
    case 'shipped':
      statusText = 'En camino';
      statusStyle = {
        backgroundColor: '#757575',
        color: 'white',
        borderRadius: '5px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '70%',
        marginTop: '10px',
      };
      break;
    case 'delivered':
      statusText = 'Entregado';
      statusStyle = {
        backgroundColor: '#388E3C',
        color: 'white',
        borderRadius: '5px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '70%',
        marginTop: '10px',
      };
      break;
    case 'not_delivered':
      statusText = 'No entregado';
      statusStyle = {
        backgroundColor: '#FFC107',
        color: 'white',
        borderRadius: '5px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '70%',
        marginTop: '10px',
      };
      break;
    case 'cancelled':
      statusText = 'Cancelado';
      statusStyle = {
        backgroundColor: '#D32F2F',
        color: 'white',
        borderRadius: '5px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '70%',
        marginTop: '10px',
      };
      break;
    case 'returned_without_payments':
      statusText = 'Devuelto. No cobrado';
      statusStyle = {
        backgroundColor: '#D32F2F',
        color: 'white',
        borderRadius: '5px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '70%',
        marginTop: '10px',
      };
      break;
    case 'returned_with_payments':
      statusText = 'Devuelto. Cobrado';
      statusStyle = {
        backgroundColor: '#388E3C',
        color: 'white',
        borderRadius: '5px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '70%',
        marginTop: '10px',
      };
      break;
    default:
      statusText = '';
      break;
  }

  return onlyText ? <td>{statusText}</td> : <td style={statusStyle}>{statusText}</td>;
};

export default OrderStatus;
