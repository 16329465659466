import React from 'react';
import { ReactComponent as LogoWhiteIcon } from '../../assets/images/logos/mm-logistica.svg';

const AuthLogo = () => {
  return (
    <div className="p-4 d-flex justify-content-center gap-2">
      <LogoWhiteIcon
        style={{
          display: 'block',
          width: '200px',
          height: 'auto'
        }}
      />
    </div>
  );
};

export default AuthLogo;
